<template>
  <div
    class="w-full bg-flame-white p-5 rounded border-2 cursor-pointer transition hover:bg-surface-100 hover:border-surface-200"
  >
    <div class="flex justify-between mb-1">
      <font-awesome-icon :icon="icon" class="text-xl" />
      <font-awesome-icon v-if="showBadge" icon="exclamation-circle" class="text-deep-red-500" />
    </div>
    <p class="text-md">{{ text }}</p>
  </div>
</template>

<script>
export default {
  name: 'HomeItemButton',
  props: {
    text: {
      type: String,
      default: ''
    },
    icon: {
      type: Array,
      default: () => ['fas', 'shop']
    },
    showBadge: {
      type: Boolean,
      default: false
    }
  }
}
</script>
