<template>
  <div class="min-h-full bg-surface-100 bg-blueprint bg-cover bg-center">
    <HomeBody />
  </div>
</template>

<script>
import PageMixin from '../mixins/Page'
import HomeBody from '../bodies/Home.vue'

export default {
  mixins: [PageMixin],
  name: 'HomePage',
  data() {
    return {
      vis: false
    }
  },
  components: {
    HomeBody
  }
}
</script>

<style lang="scss" rel="stylesheet/scss"></style>
